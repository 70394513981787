@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?o6lhyw');
  src:  url('../fonts/icomoon.eot?o6lhyw#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?o6lhyw') format('truetype'),
    url('../fonts/icomoon.woff?o6lhyw') format('woff'),
    url('../fonts/icomoon.svg?o6lhyw#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow_down:before {
  content: "\e900";
}
.icon-arrow_left:before {
  content: "\e901";
}
.icon-arrow_right:before {
  content: "\e902";
}
.icon-arrow_up:before {
  content: "\e903";
}
.icon-home:before {
  content: "\e904";
}
.icon-search:before {
  content: "\e905";
}
.icon-switch:before {
  content: "\e906";
}
.icon-upload:before {
  content: "\e907";
}
.icon-star-full:before {
  content: "\e910";
}
.icon-calendar:before {
  content: "\e908";
}
.icon-star-o:before {
  content: "\e909";
}
.icon-file-pdf-o:before {
  content: "\e90a";
}
.icon-print:before {
  content: "\e90b";
}
.icon-chain:before {
  content: "\e90c";
}
.icon-link:before {
  content: "\e90c";
}
.icon-list:before {
  content: "\e90d";
}
.icon-map-marker:before {
  content: "\e90e";
}
.icon-check:before {
  content: "\e90f";
}
.icon-envelope-o:before {
  content: "\e911";
}
.icon-edit:before {
  content: "\e912";
}
.icon-pencil:before {
  content: "\e913";
}
