// Custom (non-bootsrap) Project Styles - must be imported first in case of SASS
//@import "jquery.fancybox.scss";
@import "magnific-popup.scss";
@import "connectiv/z-index.scss";
@import "connectiv/sprite.scss";
@import "connectiv/custom-vars.scss";
@import "connectiv/conn-basics.scss";
@import "connectiv/icomoon.scss";

// Font-Awesome
//@import "font-awesome/font-awesome.scss";

//Slick Slider
@import "slick/slick.scss";
@import "slick/slick-theme.scss";

//Bootstrap
//enthält in dieser Einstellung nur häufig benötigte Dateien (bei Bedarf zusätzliche Kompontenten in der _bootstrap.scss einkommentieren)
@import "bootstrap/bootstrap";

/* ==========================================================================
   Project's custom styles
   ========================================================================== */

body {
  font-family:"Frutiger W01_n2" !important;
}
@media all and (min-width: 0px) {
  body {
    font-family:"Frutiger W01" !important;
    font-weight:200 !important;
    font-style:normal !important;
  }
}

.btn {
  line-height: 1.42857143; //fixes SASS number precision issue
  padding: 3px 12px;
  background-color: #ccc;
}

$brand-height: 55px; //Höhe der Logografik
$brand-padding-vertical: (($navbar-height - $brand-height) / 2);

.navbar-brand {
  padding: $brand-padding-vertical 10px;
}

.container-fluid {
  max-width: 1000px + $grid-gutter-width;
}

.video-play-button {
  color: #fff;
  text-shadow: 0 0 2px black;
  width: 100px;
  left: 0;
  right: 0;
  position: absolute;
  top: 210px;
  z-index: 2;
  margin: 0 auto;
  text-align: center;
}

h1, h2, h3, h4 {
  margin-top: 0;
}

h1,
h2 {
  font-size: 1.5rem;
  font-weight: 600;
}

body,
html {
  font-size: 14px;
}

html.zoom body,
html.zoom {
  font-size: 20px;
}

.checkbox input[type=checkbox],
.checkbox-inline input[type=checkbox],
.radio input[type=radio],
.radio-inline input[type=radio] {
  position: relative;
}

.modal-checktable td {
  padding: 5px;
}

.file-style {
  display: none!important;
}

.file-style + label {
  width: 220px;
  font-size: 25px;
  height: 25px;
  text-align: right;
  background: #fff;
  padding-right: 5px;
  cursor: pointer;
}

.content {
  padding: 0;
  margin-top: 50px;
}

.page-head {
  margin-bottom: 20px;
}

.page-head .icon-list,
.page-head .icon-map-marker {
  position: relative;
  top: 3px;
}

.form-group {
  margin-bottom: 20px;
}

.btn:focus,
.btn:active,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus {
  outline: none;
}

.btn-search:focus,
.btn-search:active {
  outline: none;
}

.btn-default {
  position: relative;
  line-height: 1.25;
  right: -18px;
  padding: 5px;
  box-shadow: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background: $second-grey;
  border: 0;
  color: #000;
  font-size: 1rem;
}


.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default:focus:active {
  background: $second-grey;
  border: 0;
  color: #000;
}

.btn-default:after {
  content: '';
  bottom: -5px;
  right: 0;
  border-color: transparent #90908f;
  border-style: solid;
  border-width: 0 0 5px 3px;
  position: absolute;
}

.btn-red {
  background: $red;
  color: #fff;
}

.btn-red:hover,
.btn-red:focus,
.btn-red:active,
.btn-red:focus:active {
  background: $red;
  border: 0;
  color: #fff;
}

.btn-red:after {
  border-color: transparent darken($red, 20%);
}

.btn-xs, .btn-sm {
  font-size: 0.9rem;
}

.inline-list {
  list-style: none;
  padding-left: 0;
}

.inline-list > li {
  display: inline-block;
}

.grey-box .form-control {
  border-color: $first-grey;
}

.form-control {
  height: 28px;
  border-radius: 0;
  padding: 4px 6px;
  box-shadow: none;
  color: #000;
}

.form-control:focus {
  box-shadow: none;
  border-color: $first-grey;
}

.content ul.pagination {
  margin: 20px 0;
}

ul.pagination li {
  padding: 0;
}

ul.pagination li:before {
  content: '';
  display: none;
}

/****** SECTION ******/
/****** globalnav ******/

.globalnav {
  font-size: .8rem;
  color: $global-color;
}

.globalnav .inline-list li a {
  display: block;
  color: $global-color;
  padding: 5px;
}

/****** globalnav--barren ******/

.globalnav--barren {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1001;
}

.globalnav--barren .barren {
  position: relative;
}

.globalnav--barren .barren:before,
.globalnav--barren .barren:after {
  content: '';
  bottom: 0;
  border-color: #f2f2f2;
  border-style: solid;
  border-width: 5px;
  position: absolute;
  height: 200px;
  background-color: #fff;
  z-index: 1001;
}

.globalnav--barren .barren:before {
  left: -5px;
}

.globalnav--barren .barren:after {
  right: -5px;
}

.globalnav--barren .barren > ul {
  background-color: $first-grey;
  position: relative;
  margin-bottom: 0;
}

.globalnav--barren:not(.fixed-desktop) .barren > ul {
  box-shadow: 0 2px 6px #666;
}

.globalnav--barren:not(.fixed-desktop) .barren > ul:before,
.globalnav--barren:not(.fixed-desktop) .barren > ul:after {
  content: '';
  bottom: -3px;
  border-color: transparent #90908f;
  border-style: solid;
  position: absolute;
  z-index: 999;
}

.globalnav--barren:not(.fixed-desktop) .barren > ul:before {
  border-width: 0 4px 3px 0;
  left: -4px;
}

.globalnav--barren:not(.fixed-desktop) .barren > ul:after {
  border-width: 0 0 3px 4px;
  right: -4px;
}

.globalnav--barren .barren > ul > li:first-child a {
  padding-right: 15px;
}

.globalnav--barren [class^="icon-"],
.globalnav--barren [class*=" icon-"] {
  font-size: 8px;
  color: $red;
  position: relative;
  top: -1px;
}

.globalnav--barren .icon-arrow_left {
  padding-right: 10px;
}

.globalnav--barren .icon-arrow_down,
.globalnav--barren .icon-arrow_up {
  padding-left: 5px;
}


/****** globalnav--second ******/

.globalnav--second {
  margin-top: 35px;
  margin-bottom: 10px;
}

.globalnav--second ul {
  margin-bottom: 0;
  position: relative;
}

.globalnav--second li {
  display: block;
  float: left;
  margin-right: 10px;
}

.globalnav--second li > a {
  display: block;
  padding: 5px;
  color: $dark-grey;
}

/********* globalnav Suche ***********/

/*
.globalnav__search .btn-search {
  color: $dark-grey;
  font-size: 18px;
  border: none;
  width: 25px;
  height: 25px;
  background: transparent;
  padding: 0;
  line-height: 1;
}

.globalnav__search.open .btn-search {
  background: $red;
  color: #fff;
}

.globalnav__searchbody {
  display: none;
  position: absolute;
  left: 0;
  padding: 10px 0;
  background: #fff;
  box-shadow: 2px 2px 2px $second-grey;
}

.globalnav__searchbody input {
  min-width: 300px;
  background: $first-grey;
  border: 1px solid $first-grey;
  padding: 2px 5px;
  float: left;
}

.globalnav__searchbody .btn {
  margin-left: 10px;
  right: -3px;
  padding: 2px 5px;
}

.globalnav__search.open .globalnav__searchbody {
  display: block;
}
*/

/****** headerlogo *****/

.headerlogo {
  padding-bottom: 20px;
}

.logo {
  padding-top: 5px;
  color: #000;
}

.logo .klar {
  font-size: 22px;
  line-height: 55px;
  float: right;
}

.logo .klar-punkt {
  font-size: 27px;
  font-weight: 700;
}

.page-name {
  font-size: 21px;
  padding-top: 10px;
  color: #000;
  display: block;
  line-height: 27px;
}

/****** nav *****/

nav.navbar {
  border-bottom: 0;
  margin-bottom: 0px;
  min-height: 0;
  box-shadow: 0 2px 6px #666;
}

.navbar-header .navbar-home {
  display: block;
  padding: 7px 10px 7px 0;
  margin-left: 10px;
  font-size: 23px;
  color: $red;
  float: left;
  line-height: 1;
}

#navbar {
  border-top: 1px dotted #000;
}

.nav [class^="icon-"],
.nav [class*=" icon-"] {
  font-size: 9px;
  color: $red;
  position: relative;
  top: -5px;
  margin-left: 3px;
  margin-right: 8px;
}

.nav a:hover [class^="icon-"],
.nav a:hover [class*=" icon-"] {
  color: #fff;
}

.nav > li > a,
#navbar .rememberlist {
  display: block;
  padding: 10px;
  height: 2.5em;
  color: #000;
  box-sizing: content-box;
  position: relative;
}

#navbar .rememberlist {
  padding: 10px 0 10px 10px;
  line-height: 20px;
  display: inline-block;
}

.nav > li > a:hover,
.nav > li > a:focus,
#navbar .rememberlist:hover {
  background: $red;
  color: #fff;
}

.nav > li > a.arrow-down:hover:after {
  border-top-color: #fff;
}

.navbar__searchbody {
  display: none;
}

/***** Nav fixed *****/

nav.fixed-desktop {
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  margin-bottom: 0;
  z-index: 10;
}

nav.fixed-desktop #navbar {
  border-top: 0;
  box-shadow: 0 2px 6px #666;
  position: relative;
}

/***** dropdown-menu *****/

li.dropdown.open > a {
  color: #fff;
  background: $red;
}

li.dropdown > a .icon-arrow_down {
  display: inline-block;
}

li.dropdown > a .icon-arrow_up {
  display: none;
}

li.dropdown.open > a [class^="icon-"],
li.dropdown.open > a [class*=" icon-"] {
  color: #fff;
}

li.dropdown.open > a .icon-arrow_down {
  display: none;
}

li.dropdown.open > a .icon-arrow_up {
  display: inline-block;
}

ul.dropdown-menu {
  padding-top: 0;
  margin-top: 0;
  padding-bottom: 0;
  width: 300px;
  border-radius: 0;
  font-size: inherit;
}

ul.dropdown-menu li {
  border-bottom: 1px dotted $dark-grey;
}

ul.dropdown-menu li:last-child {
  border-bottom: none;
}

ul.dropdown-menu > li > a {
  padding: 0.5em;
}

ul.dropdown-menu > li > a:hover {
  background-color: $dark-grey;
  color: #fff;
}

/****** navbar-toggle *****/

.navbar-toggle {
  float: left;
  margin: 0 5px;
  padding: 10px;
  border: 0;
  border-radius: 0;
}

.navbar-default .navbar-toggle:hover {
  background-color: #fff;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: #000;
}

.navbar-toggle .icon-bar {
  height: 4px;
  width: 24px;
}

.mobile-search {
  float: left;
  padding: 0 10px;
}

.mobile-search .btn-search {
  color: #000;
  font-size: 32px;
  border: none;
  background: transparent;
  padding: 0;
  line-height: 1;
}

.mobile-search .btn-search span {
  line-height: 40px;
}

.mobile-search__searchbody {
  position: relative;
  display: none;
  margin: 10px 0 10px 0;
  background: #fff;
  clear: left;
}

.mobile-search__searchbody input {
  width: 100%;
  background: $first-grey;
  border: 1px solid $first-grey;
  padding: 4px 60px 4px 5px;
  float: left;
}

.mobile-search__searchbody .btn {
  position: absolute;
  right: 0;
  height: 29px;
  top: 0;
  border-radius: 0;
}

.mobile-search__searchbody .btn-default:after {
  content: none;
}

.mobile-search.open + .mobile-search__searchbody {
  display: block;
}


/***** Main-Slider ****/

.main-slider.slick-slider {
  margin-bottom: 20px;
}

.main-slider__item  {
  display: block;
  position: relative;
  color: #000;
  overflow: hidden;
}

.main-slider__item:hover,
.main-slider__item:focus,
.main-slider__item:active {
  color: #000;
  outline: none;
}

.main-slider__overlay {
  position: absolute;
  left: 15px;
  right: 15px;
  bottom: 16px;
  padding: 20px 20px 20px 20px;
  background: rgba(255, 255, 255, 0.8);
}

.main-slider__title {
  display: block;
  font-size: 1.625rem;
  margin-bottom: 3px;
  font-weight: 600;
}

.main-slider__subtitle {
  display: block;
  font-size: 2rem;
  font-weight: 300;
  float: left;
  margin-top: -10px;
}

.main-slider__button {
  display: inline-block;
  float: right;
  background-color: $red;
  color: #fff;
  text-align: center;
  font-weight: 300;
  font-size: 16px;
  line-height: 42px;
  padding-left: 30px;
  height: 42px;
  padding-right: 30px;
  margin-top: -15px;
}

.main-slider .slick-dots {
  margin-bottom: 0;
  bottom: 30px;
}

.main-slider .slick-dots li,
.main-slider .slick-dots li button,
.main-slider .slick-dots li button:before {
  height: 13px;
  width: 13px;
}

.main-slider .slick-dots li button:before {
  content: '';
  border: 1px solid #000;
  border-radius: 100%;
  opacity: 1;
  line-height: 13px;
}

.main-slider .slick-dots li.slick-active button:before {
  opacity: 1;
  background: $red;
}

/***************************************************************************************************/


.start-seite-slider {
  background: #C6394A;
  padding: 30px 50px 15px 50px;
  margin-bottom: 30px;
}

.start-seite-slider .slick-dots li.slick-active button:before {
  color: #f30303;
  opacity: 1;
  background: #ffff;
}
.start-seite-slider .slick-dots li button:before {

  border-radius: 50px !important;
  content: "" !important;
  width: 15px !important;
  height: 15px !important;
  background: #C6394Ak !important;
  border: 2px solid #ffff !important;
  opacity: 1 !important;
}


.startseite-slider__head {
  padding: 20px;
  color: #FFFFFF;
}
.startseite-slider__items {
  height: auto !important;
}
.startseite-slider__overlay {
  color: #FFFFFF !important;
  background: none !important;
  padding: 10px 20px 0px 20px;
}
.startseite-slider__sbutitle {
  font-size: 16px;
  font-weight: 400;
  display: block;
  padding-bottom: 15px;
}
.startseite-slider__text {
  font-size: 14px;
}

.startseite-slider-btn {
  color: #0C0C0C;
  text-decoration: none;
  padding: 10px 30px;
  border-radius: 0 !important;
  background: #ffff;
  margin-top: 15px;
  box-shadow: 0px 3px 6px #00000029;
}
.startseite__img-box {
  display: flex;
  justify-content: flex-end;
}
.startseite-arrows {
  height: 23px !important;
  width: 14px !important;
}
.start-seite-slider.slick-dots {
  bottom: 5px !important;
}


/***** startseite-project *****/

.startseite-project {
  padding: 30px;
  background-position: right;
  background-size: cover;
  margin-bottom: 30px;
}
.startseite-project-box {
  padding: 20px;
  background: #ffffffd4;
}
.startseite-project__title {
  color:#C6394A  !important;
}

.startseite-project-btn {
  padding: 6px 30px;
  border-radius: 0 !important;
  background: #C6394A;
  color: #ffffff;
  margin-top: 15px;
  &:hover, &:focus {
    color: #FFFFFF;
    background-color: #B93747;
  }
}
.startseite-proejct-img-box {
  justify-content: center !important;
  align-items: center;
  padding-top: 50px;
}
.startseite-project-img {
  height: 152px;
}
.startseite-project-sbutitle {
  color: #0C0C0C ;
  font-weight: bold;
}

/***** landingpage-project *********/

.landingpage-project {
  padding: 25px 15px;
  background-size: cover;
  margin-bottom: 30px;
  background-position: top;
}
.landingpage-project-box {
  padding: 15px 25px;
  background: #fff;
  opacity: .8;
  margin-top: 35px;
}

.landingpage-project-img {
  height: 120px !important;
  padding-left: 75px;
}

/******** landingpage-create-projec ******/
.landingpage-create-project__sbutitle {
  margin-bottom: 15px;
  color: #C6394A;
  font-weight: 700;
  font-size: 16px;
  display: block;
}
.landingpage-create-project__items {
  margin-bottom: 25px;
}
.innovation__table {
  display: none;
}
.rural-economy__table {
  display: none;
}
.general-interest__table {
  display: none;
}
.landingpage-create-project__title {
  font-size: 1.55rem;
  font-weight: 600;
  background: #ffff;
  padding: 10px;
  margin-bottom: 20px;
  display: block;
}




/****************************************************************************************/


.start-seite-slider .slick-dots li {
  margin: 0 !important;
}

/***** Subheadline *****/

.subheadline {
  display: block;
  font-size: 16px;
  line-height: 1.3;
  font-weight: 600;
  margin-bottom: 20px;
}

/***** Bar-left ****/

.bar-left {
  border-left: 5px solid $red;
  padding: 10px 0 10px 15px;
}


/***** Topics ****/

.topics__teaser-new {
  margin-bottom: 15px;
}

.topics-overlay__new-category {
  display: block;
  background: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  color: #C6394A;
  padding: 25px;
  font-weight: 600;
  background: #F2F2F2  no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  text-align: center;
  margin-bottom: 20px;

  &:hover {
    color: #FFFFFF;
    background: #C6394A ;
    transition: 600ms background;
    cursor : pointer;
  }
}
.topics__item {
  display: block;
  position: relative;
  margin-bottom: 20px;
  height: 200px;
  overflow: hidden;
}

.topics__overlay {
  position: absolute;
  left: 15px;
  right: 15px;
  bottom: 15px;
  background: rgba(255, 255, 255, 0.8);
  font-size: 1.5rem;
  color: #000;
  padding: 10px;
  font-weight: 600;
}
.landingpage-insert__btn {
  cursor: pointer;
}
.landingpage-insert-box {
  padding : 30px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.landingpage-insert-items {
  background: #FFFFFF;
  padding: 15px;
}

.p-btn {
  color: #C6394A;
  margin-top: 10px;
  text-align: center;
  font-weight: 500;
}
.landingpage-insert__paragraph {
  font-weight: 500;
}
.oder {
  text-align: center;
  margin-top: 15px;
}
.path-clip {
  clip-path: polygon(69% 49%,0 100%,0 52%,0 0);
  background: #c6394a;
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 35px;
  color: #FFFFFF;
}
.myBtn {
  color: #FFFFFF !important;
}
.project-erstellen-btn {
  display: block;
  padding: 15px;
  font-weight: 600;
}
.landingpage-create-project__los-geht {
  padding: 157px 20px;
}

/***** startseite-future-project ******/
.future-project {
  margin-bottom: 30px;
  padding: 20px 0px;
}
.future-project__box {
  padding: 6px 0;
}
.future-project-box__img {
  //height: 100%;
}

.startseite-copyrights {
  color : #0C0C0C;
}

/****** projects ******/

.projects {
  border-left: 5px solid $red;
  background: $first-grey;
  color: #000;
  padding: 10px 0 30px;
  margin-bottom: 20px;
}

.projects__head {
  display: block;
  text-transform: uppercase;
  font-size: 12px;
  padding-left: 10px;
  padding-bottom: 10px;
}

.project-slider.slick-slider {
  margin-bottom: 0;
}

.project-slider__item {
  color: #000;
  padding: 10px;
  border-right: 1px dotted $dark-grey;
}

.project-slider__item:hover,
.project-slider__item:focus {
  color: #000;
}

.project-slider__item:focus,
.project-slider__item:active {
  outline: none;
}

.project-slider__title {
  display: block;
  font-weight: 600;
  margin-bottom: 10px;
}

.project-slider .slick-next,
.project-slider .slick-prev {
  height: 30px;
  width: 30px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: $second-grey;
  color: #000;
  top: auto;
  transform: none;
  bottom: -33px;
}

.project-slider .slick-next {
  right: 20px;
}

.project-slider .slick-prev {
  right: 60px;
  left: auto;
}

.project-slider .slick-next:before,
.project-slider .slick-prev:before {
  color: #000;
  opacity: 1;
  font-size: 11px;
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.project-slider .slick-next:before {
  content: "\e902";
}

.project-slider .slick-prev:before {
  content: "\e901";
}
.project-slider .slick-next:after,
.project-slider .slick-prev:after {
  content: '';
  position: absolute;
  bottom: 0;
  right: -4px;
  border-color: transparent $dark-grey;
  border-style: solid;
  border-width: 0 0 3px 4px;
}

/***** Breadcrumb ******/

.breadcrumb {
  font-size: 0.7em;
  text-transform: uppercase;
  border-radius: 0;
  border-bottom: 1px dotted #000;
}

.breadcrumb > li > a {
  color: $breadcrumb-color;
}

.breadcrumb > li + li:before {
  content: $breadcrumb-separator;
  font-family: $breadcrumb-font-family;
  font-size: 0.7em;
}

/****** create-project ******/

.create-project-outer-text {
  max-width: 810px;
  margin-top: 30px;
}

form.create-project {
  margin-top: 60px;
  margin-bottom: 35px;
}

.create-project label {
  font-weight: 500;
}

.create-project select.inputEmpty,
.create-project input.inputEmpty,
.create-project textarea.inputEmpty,
.create-project button.inputEmpty,
.create-project label.inputEmpty {
  border: 1px solid $red !important;
}

.alert-header {
  display: block;
  font-weight: 700;
}

.form-group-inline-md {
  margin-bottom: 15px;
}

.create-project .form-subhead {
  font-weight: 600;
  display: block;
  margin: 35px 0 25px;
}

.generate {
  color: $dark-grey;
  display: inline-block;
}

.generate_part {
  display: inline-block;
  margin-right: 20px;
}

.char_num {
  color: $dark-grey;
  //display: table-caption;
  font-size: 0.85em;
  //float: right;
  //caption-side: bottom;
}

.create-project button.categoryPopupLink,
.create-project button.financingPopupLink {
  border: none;
  text-align: left;
  height: 28px;
  background: #fff;
  padding: 4px 6px;
  color: #000;
  width: 100%;
}

.create-project input[name="create-project[financingAdd]"] {
  margin-top: 20px;
}

.input-file-field {
  display: block;
  width: 100%;
  min-height: 28px;
  height: auto;
  border: 1px solid $first-grey;
  background: #fff;
  padding-right: 30px;
  position: relative;
  cursor: pointer;
}

.input-file-field:after {
  content: "\e907";
  font-family: 'icomoon';
  display: block;
  position: absolute;
  right: 5px;
  font-size: 26px;
  line-height: 1;
  top: 0;
  bottom: 0;
}

input[type="file"].input-file {
  display: none;
}

.create-project .copyrights {
  font-size: 0.85em;
  display: block;
  color: $dark-grey;
}

.create-project .required_fields {
  font-size: 0.85em;
  display: block;
  color: $dark-grey;
  margin-bottom: 90px;
}

.create-project .btn-red {
  float: right;
  padding-right: 20px;
}

/****** Projektliste ******/

.sort {
  float: left;
}

.sort select {
  width: 185px;
  padding: 3px 0;
}

.sort.disabled {
  color: $second-grey;
}

.sort.disabled select {
  border-color: $second-grey;
}

.proj-list-style {
  color: $second-grey;
  font-weight: 600;
  cursor: pointer;
}

.proj-list-style [class^="icon-"],
.proj-list-style [class*=" icon-"] {
  font-size: 18px;
  margin-right: 8px;
}

.proj-list-style.active,
.proj-list-style:hover {
  color: $red;
}

.proj-list-style + .proj-list-style {
  margin-left: 45px;
}

.proj-item {
  background: $first-grey;
  padding: 15px;
  display: block;
  color: #000;
}

.proj-item:hover {
  color: #000;
}

.proj-item > .img-outer {
  height: 9.286rem;
  width: 217px;
  float: left;
  margin-right: 20px;
}

.proj-item > .img-outer > img {
  max-height: 100%;
  margin: 0 auto;
}

.proj-item__descr {
  display: block;
  position: relative;
}

.proj-item__head {
  font-size: 1.5em;
  display: block;
  line-height: 1;
  margin-bottom: 15px;
}

.proj-item__text {
  display: block;
  line-height: 1.3;
}

.proj-item__bearer {
  font-size: 0.85em;
  padding-right: 70px;
  position: inherit;
  display: block;
  clear: both;
  margin-top: 20px;
}

.proj-item .btn-default {
  position: absolute;
  bottom: 0;
  padding: 5px 30px 5px 10px;
}

.proj-item + .proj-item,
.proj-item + .proj-item__delete-outer {
  margin-top: 20px;
}

.proj-item__delete-outer {
  text-align: right;
}

.proj-item__delete {
  background: $second-grey;
  padding: 5px 10px;
  display: inline-block;
  color: #000;
  float: right;
  z-index: 5;
  position: relative;
  top: 5px;
  right: 5px;
}

.proj-item__delete.btn {
  right: -3px;
  top: 10px;
}

.proj-item__delete:hover {
  background: $red;
  color: #fff;
}

/****** filter-left *******/

.filter-left {
  background: $first-grey;
  padding: 15px;
  margin-bottom: 30px;
}

.filter-left__section + .filter-left__section {
  margin-top: 30px;
}

.filter-left__section-head {
  font-weight: 600;
  display: block;
  margin-bottom: 15px;
}

.filter-left__section-head [class^="icon-"],
.filter-left__section-head [class*=" icon-"] {
  font-size: 9px;
  float: right;
  line-height: 18px;
}

.filter-left__section-head .btn-filter {
  display: block;
}

.filter-left__section-options label {
  font-weight: 500;
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 6px;
}

.filter-left__section-options input[type="checkbox"],
.filter-left__section-options input[type="radio"] {
  appearance: none;
  margin-right: 10px;
  outline: none!important;
  position: absolute;
  margin-top: 3px;
  left: 0;
}

.filter-left__section-options input[type="checkbox"]:before {
  content: "\e90f";
  color: #fff;
  display: block;
  height: 12px;
  width: 12px;
  border-radius: 3px;
  border: 1px solid $dark-grey;
  background: #fff;
  font-family: 'icomoon';
  font-size: 0.7em;
}

.filter-left__section-options input[type="checkbox"]:checked:before {
  border-color: $blue;
  background: $blue;
}

.filter-left__section-options input[type="radio"]:before {
  content: '';
  display: block;
  height: 14px;
  width: 14px;
  border: 1px solid $dark-grey;
  border-radius: 100%;
  background: #fff;
}

.filter-left__section-options input[type="radio"]:checked:before {
  border: 5px solid $blue;
}

.filter-left__section-options.scroll {
  height: 150px;
  background: #fff;
  padding: 15px 10px 10px 10px;
}

.filter-left__section-options.scroll > .scroll {
  height: 100%;
  overflow-y: scroll;
}



/***** project-detail ******/

.project-detail__slider .slick-dots {
  margin-bottom: 0;
  bottom: 15px;
  height: 30px;
  background: rgba(255,255,255,0.8);
}

.project-detail__slider .slick-dots li,
.project-detail__slider .slick-dots li button,
.project-detail__slider .slick-dots li button:before {
  height: 13px;
  width: 13px;
}

.project-detail__slider .slick-dots li button:before {
  content: '';
  border: 1px solid #000;
  border-radius: 100%;
  opacity: 1;
  line-height: 13px;
}

.project-detail__slider .slick-dots li.slick-active button:before {
  opacity: 1;
  background: $red;
}

.project-detail__img {
  height: 250px;
  width: 100%;
  position: relative;
}

.project-detail__img:focus {
  outline: none;
}

.project-detail__img > img {
  max-height: 100%;
  margin: 0 auto;
}

.project-detail__img .img-copyright {
  color: #fff;
  text-shadow: 0 0 3px #000;
  font-size: 0.85em;
  position: absolute;
  bottom: 0;
  right: 0;
}

.project-detail-box {
  margin-top: 20px;
  background: $first-grey;
  border-left: 5px solid $red;
  padding: 10px 10px 15px 10px;
}

.project-detail__section {
  display: table;
  width: 100%;
  float: left;
}

.project-detail__section + .project-detail__section {
  margin-top: 15px;
}

.project-detail__section > * {
  display: table-cell;
}

.project-detail__section > img {
  display: inline-block;
  margin-right: 10px;
}

.project-detail__section--top {
  margin-bottom: 30px;
}

.project-detail__section--top .project-detail__field-title {
  font-size: 1.2em;
}

.project-detail__save {
  text-align: right;
}

.project-detail__save a {
  color: #000;
  padding: 0 10px;
  text-decoration: none;
  position: relative;
}

.project-detail__save a[data-title]:hover:before {
  content: '';
  position: absolute;
  top: 100%;
  margin-top: 11px;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 3px solid $second-grey;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.project-detail__save a[data-title]:hover:after {
  content: attr(data-title);
  padding: 0 2px;
  color: #000;
  font-size: 12px;
  line-height: 18px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
  margin-top: 14px;
  white-space: nowrap;
  z-index: 20;
  border-radius: 2px;
  background-color: $second-grey;
}

.project-detail__field-title {
  width: 235px;
  line-height: 1.5em;
  font-weight: 600;
  vertical-align: top;
}

/******* project-advice ********/

.project-advice__overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 5000;
}

.project-advice__overlay .project-advice__overlay-bg {
  display: block;
  background: #000;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: .6;
}

.project-advice__outer {
  position: relative;
  display: block;
  height: 100%;
  overflow: scroll;
}

.project-advice__box {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 50%;
  transform: translate(-50%,0);
  background-color: #fff;
  padding: 40px;
  margin: 50px 0;
}



.grey-box {
  display: block;
  background: $first-grey;
  color: #000;
  padding: 15px;
  margin-bottom: 20px;
}

/***** project-box *****/

.project-box {
  margin-bottom: 20px;
}

.project-box:hover {
  color: #000;
}

.project-box__head {
  display: block;
  font-weight: 600;
  margin-bottom: 15px;
  word-break: break-word;
}

.project-box__text {
  display: block;
  margin-bottom: 30px;
}

.project-box__bearer {
  display: block;
  font-size: 0.85em;
  margin-bottom: 25px;
}

.project-box .btn-default {
  float: right;
  padding: 5px 30px 5px 10px;
}

/***** map *****/

.map__head {
  font-size: 1.5em;
  font-weight: 600;
  display: block;
  margin-bottom: 15px;
}

.infowindow__head {
  font-weight: 600;
  display: block;
  margin-top: 20px;
  margin-bottom: 15px;
}

.infowindow__descr {
  display: block;
}

.infowindow__bearer {
  display: block;
  margin-top: 25px;
  font-size: 0.85em;
}

.infowindow__sessionlist {
  background: #ccc;
  padding: 5px 10px;
  display: inline-block;
  color: #000;
  float: left;
  z-index: 5;
  position: relative;
  margin: 20px 0;
}

.gm-style-iw {
  width: 236px !important;
  top: 15px !important; // move the infowindow 15px down
  left: 15px !important;
  right: 0 !important;
  overflow: hidden;
}

.gm-style-iw > div {
  width: 236px !important;
}

.gm-style-iw > div > div {
  overflow: hidden!important;
}

.ib-wrapper {
  width: 252px!important;
}

.ib-inner {
  width: 250px!important;
}

.btn-infowindow {
  float: right;
  right: -11px;
  margin: 20px 0;
  padding-right: 50px;
}

.btn-infowindow:after {
  content: none;
}

.iw-container {
  width: 225px;
}

.ib-wrapper.outer:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-top: 14px solid transparent;
  border-bottom: 15px solid transparent;
  border-right: 11px solid rgba(0,0,0,0.2);
  position: absolute;
  bottom: 60px;
  left: -10px;
}

.ib-wrapper.outer:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-bottom: 13px solid transparent;
  border-right: 9px solid rgba(242,242,242,1);
  position: absolute;
  bottom: 62px;
  left: -8px;
}

/****** Footer-top *******/

.footer-top {
  margin-top: 55px;
  border-top: 1px dotted $dark-grey;
  padding-top: 20px;
  font-size: 0.85em;
}

.footer-top__head {
  color: $dark-grey;
  margin-bottom: 20px;
  font-size: 1rem;
}

.footer-top li a {
  color: $dark-grey;
  display: block;
  padding: 3px 0;
}

.footer-top li a:hover {
  color: $dark-grey;
}

/**** partner ****/

.partner {
  margin-top: 40px;
  margin-bottom: 60px;
}

.partner__head {
  color: $dark-grey;
  display: block;
  margin-bottom: 25px;
}

.partner a {
  margin-right: 65px;
}

.partner a img {
  height: 65px;
}

footer {
  background: $dark-grey;
  color: #000;
  padding: 15px 0;
}

.footer-opts,
.footer-links {
  margin-bottom: 0;
}

.footer-opts li {
  text-align: right;
}

.footer-opts li:first-child {
  margin-bottom: 10px;
}

.footer-opts a {
  color: #000;
  position: relative;
}

.footer-opts a:hover {
  color: #000;
}

.footer-opts [class^="icon-"],
.footer-opts [class*=" icon-"] {
  position: absolute;
  left: -35px;
  font-size: 29px;
  top: 50%;
  transform: translateY(-50%);
}

.footer-links li {
  display: inline-block;
}

.footer-links li a {
  color: #fff;
  padding: 5px 15px 5px 0;
}

.footer-links a:hover {
  color: #fff;
}

.-webcms-component img {
  max-width: 100%;
}

.filter-left__section .btn {
  margin-top: 5px;
}

.pagination > .active > a {
  background: #ccc;
  border-color: #ccc;
  color: #000;
}

.pagination > li > a {
  color: #000;
}

.pdf-download {
 background: #ccc;
  border-radius: 4px;
  color: #000;
  padding: 5px 15px 5px 10px;
  float: right;
}

.pdf-download span.icon-file-pdf-o:before{
  position: relative;
  top: 1px;
  padding-left: 5px;
}

.projektmerkliste {
  margin-bottom: 20px;
}

.create-project .cell a:hover {
  cursor: pointer;
}

a[data-toggle=popover] {
  padding: 2px;
  position: absolute;
  top: -29px;
  right: 0;
  z-index: 5;
}

.popover {
  border-radius: 0;
  box-shadow: none;
  min-width: 200px;

  .popover-content {
    min-width: 200px;
  }
}
.popover-title {
  border-radius: 0;
  background: $first-grey;
}

.create-project .cell {
  position: relative;
}

.picture-upload .cell {
  vertical-align: top !important;
}

.picture-upload .checkbox {
  margin-top: 0;
}

.accordion {
  margin-left: 35px;
  margin-bottom: 70px;
}

.accordion .panel-body {
  padding-bottom: 10px;
}

#et-opt-out {
  background-color: $brand-primary!important;
}

.image-gallery__item {
  display: block;
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-bottom: 40px;
  position: relative;
}

.clip {
  width: 30px;
  height: 30px;
  display: block;
  position: absolute;
  left: 10px;
  bottom: -3px;
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
  background-color: #ccc;
  background-image: url(../img/kamera-icon.png);
  background-repeat: no-repeat;
  background-position: center;
  &:after {
    content: '';
    bottom: 0;
    right: -4px;
    border-color: transparent #90908f;
    border-style: solid;
    border-width: 0 0 3px 4px;
    position: absolute;
  }
}

@media (max-width: $screen-xs-max) {

  nav.navbar {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
  }

  #navbar > div.pull-right {
    float: none!important;
    margin-top: 10px;
    margin-left: -10px;
    margin-right: -10px;
  }

  #navbar > div.pull-right a {
    padding: 10px;
    display: block;
    line-height: 34px;
  }

  #navbar .navbar-nav {
    margin: 0 -10px 20px -10px;
  }

  #navbar .navbar-nav a {
    display: block;
    line-height: 34px;
  }

  .navbar-static-top .logo.mobile {
    position: absolute;
    right: 10px;
    top: 2px;
  }

  .navbar-static-top .logo.mobile img {
    height: 25px;
    width: auto;
  }

  .navbar-header .navbar-home {
    margin-left: -7px;
    padding-left: 7px;
  }

  .navbar + div.container {
    margin-top: 70px;
  }

  .main-slider__title {
    font-size: 1.625rem !important;
  }

  .main-slider__overlay {
    padding: 10px;
  }

  .subheadline {
    font-size: 18px;
  }



  footer {
    padding-bottom: 0;
  }

  .footer-links {
    margin-left: -10px;
    margin-right: -10px;
  }

  .footer-links li {
    width: 33.3333%;
    float: left;
    text-align: center;
  }

  .footer-links li a {
    display: block;
    padding: 15px 10px;
  }

  .footer-links li {
    border-top: 1px solid #ccc;
  }

  .footer-links li:not(:last-of-type) {
    border-right: 1px solid #ccc;
  }

  .footer-opts {
    float: none!important;
    margin-bottom: 15px;
  }

  nav.fixed-desktop #navbar {
    box-shadow: none;
    border-top: 1px dotted #000;
  }

  .content {
    margin-top: 20px;
  }

  .page-head .sort {
    width: 100%;
    float: none!important;
    margin-bottom: 20px;
  }

  .page-head .sort select[name="sort"] {
    width: 100%;
    margin-top: 10px;
    height: 36px;
  }

  .page-head .sort ~ div.pull-right {
    float: none!important;
  }

  .page-head .sort ~ div.pull-right a {
    padding: 5px 0;
  }

  .breadcrumb {
    margin-top: 5px;
  }

  .proj-item > .img-outer {
    width: 100%;
    padding-right: 35px;
    margin: 0 0 10px 0;
  }

  .project-detail__field-text,
  .project-detail__field-title,
  .project-detail__section {
    display: block;
  }

  .project-detail__save {
    display: block;
    text-align: left;
    margin: 10px 0 0 0;
  }

  .project-detail__save a {
    display: inline-block;
    padding: 5px 15px;
    margin-right: 10px;
    background: #ccc;
    border-radius: 4px;
  }

  .project-detail__save a:focus {
    outline: none;
  }

  .project-detail-box {
    margin-bottom: 20px;
  }

  .proj-item__bearer {
    padding-bottom: 30px;
  }

  .filter-left {
    display: none;
  }

  .mobile-filter {
    width: 100%;
    height: 36px;
    border: none;
    margin-bottom: 20px;
  }

  .mobile-filter:after {
    font-family: icomoon!important;
    content: "\e900";
    padding-left: 10px;
    font-size: 8px;
    position: relative;
    top: -1px;
  }

  .mobile-filter.open:after {
    content: "\e903";
  }

  .search-in {
    margin-top: 20px;
    width: 100%;
  }

  .search-in #searchFor {
    width: 100%;
    height: 28px;
  }

  .pdf-download {
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
  }

  #map {
    max-height: 400px;
    margin-bottom: 20px;
  }

  #map .ib-wrapper.outer {
    transform: scale(0.75);
  }

  .image-gallery__item {
    height: 130px;
  }

}


/* Kleine Geräte (Tablets, 768px und breiter) */
@media (min-width: $screen-tablet) {

  nav.navbar {
    border-bottom: 0;
    margin-bottom: 20px;
    box-shadow: none;
  }

  /***** Nav fixed *****/

  nav.fixed-desktop {
    top: 25px;
  }

  nav.fixed-desktop #navbar {
    height: $navbar-height;
  }

  .nav > li > a:hover:before,
  .nav > li > a:focus:before,
  #navbar .rememberlist:hover:before {
    content: '';
    position: absolute;
    top: 0px;
    right: -4px;
    border-color: transparent #b11233;
    border-style: solid;
    border-width: 3px 0 0 4px;
  }


  nav.fixed-desktop + div {
    margin-top: 75px;
  }

  .fixed-desktop .navbar__outer {
    position: relative;
  }

  .fixed-desktop .navbar__outer:before,
  .fixed-desktop .navbar__outer:after {
    content: '';
    bottom: 0;
    border-color: #fff;
    border-style: solid;
    border-width: 3px;
    position: absolute;
    height: 200px;
    background-color: #fff;
    z-index: 1001;
  }

  .fixed-desktop .navbar__outer:before {
    left: -6px;
  }

  .fixed-desktop .navbar__outer:after {
    right: -6px;
  }

  .fixed-desktop .navbar__inner-outer {
    position: relative;
  }

  .fixed-desktop .navbar__inner-outer:before,
  .fixed-desktop .navbar__inner-outer:after {
    content: '';
    bottom: -3px;
    border-color: transparent #90908f;
    border-style: solid;
    position: absolute;
    z-index: 999;
  }

  .fixed-desktop .navbar__inner-outer:before {
    border-width: 0 4px 3px 0;
    left: -4px;
  }

  .fixed-desktop .navbar__inner-outer:after {
    border-width: 0 0 3px 4px;
    right: -4px;
  }
/*
  .popover.left {
    right: 15px;
  }

  a[data-toggle="popover"] {
    top: 1px;
    right: -22px;
  }

  /***** nav *****/

  /*
  nav.navbar {
    border-bottom: 0;
    margin-bottom: 20px;
    box-shadow: none;
  }

  .navbar-header {
    position: relative;
    z-index: 1;
  }

  .navbar-header .navbar-home {
    display: block;
    padding: 10px 10px 10px 0;
    margin-right: 10px;
    font-size: 29px;
    margin-left: 0;
    float: none;
    height: 55px;
  }

  .nav > li > a:hover,
  .nav > li > a:focus,
  #navbar .rememberlist:hover {
    padding-top: 13px;
    margin-top: -3px;
    box-shadow: 2px 2px 6px #cccccc;
  }

  /***** Nav fixed *****/
 /*
  nav.fixed-desktop {
    top: 25px;
  }

  nav.fixed-desktop #navbar {
    height: $navbar-height;
  }

  .nav > li > a:hover:before,
  .nav > li > a:focus:before,
  #navbar .rememberlist:hover:before {
    content: '';
    position: absolute;
    top: 0px;
    right: -4px;
    border-color: transparent #b11233;
    border-style: solid;
    border-width: 3px 0 0 4px;
  }


  nav.fixed-desktop + div {
    margin-top: 75px;
  }

  .fixed-desktop .navbar__outer {
    position: relative;
  }

  .fixed-desktop .navbar__outer:before,
  .fixed-desktop .navbar__outer:after {
    content: '';
    bottom: 0;
    border-color: #fff;
    border-style: solid;
    border-width: 3px;
    position: absolute;
    height: 200px;
    background-color: #fff;
    z-index: 1001;
  }

  .fixed-desktop .navbar__outer:before {
    left: -6px;
  }

  .fixed-desktop .navbar__outer:after {
    right: -6px;
  }

  .fixed-desktop .navbar__inner-outer {
    position: relative;
  }

  .fixed-desktop .navbar__inner-outer:before,
  .fixed-desktop .navbar__inner-outer:after {
    content: '';
    bottom: -3px;
    border-color: transparent #90908f;
    border-style: solid;
    position: absolute;
    z-index: 999;
  }

  .fixed-desktop .navbar__inner-outer:before {
    border-width: 0 4px 3px 0;
    left: -4px;
  }

  .fixed-desktop .navbar__inner-outer:after {
    border-width: 0 0 3px 4px;
    right: -4px;
  }

  .main-slider__item {
    height: 380px;
  }

  .topics__item {
    height: 170px;
  }

  /******** create-project ******/

  /*
  .char_num {
    float: none;
    text-align: right;
  }

  .create-project .form-group {
    display: table;
  }

  .create-project .form-group:before,
  .create-project .form-group:after {
    //content: '';
    display: table;
    clear: both;
  }

  .generate-outer {
    margin-top: -10px;
  }

  .generate {
    margin-left: 165px;
    margin-top: 10px;
  }

  .form-group-inline-md {
    float: left;
    margin-bottom: 0;
    display: table-row;
  }

  .create-project .cell {
    display: table-cell;
  }

  //.create-project .cell > * {
  //  display: inline-block;
  //}

  .create-project .cell-label {
    vertical-align: middle;
  }

  .create-project .cell-label--baseline {
    vertical-align: baseline;
  }

  .create-project .cell--top {
    vertical-align: top;
  }

  .create-project label {
    margin-bottom: 0;
    //float: left;
    //display: table-cell;
  }

  .create-project .form-control {
    //float: left;
    //display: table-cell;
  }

  .create-project .left-label {
    width: 165px;
  }

  .create-project input[name="create-project[name]"],
  .create-project input[name="create-project[bearerName]"],
  .create-project input[name="create-project[street]"],
  .create-project input[name="create-project[projStreet]"],
  .create-project input[name="create-project[contactPerson]"],
  .create-project select[name="create-project[targetGroupId]"],
  .create-project select[name="create-project[categoryArray][]"],
  .create-project a.categoryPopupLink {
    width: 200px;
    display: block; //Für die a-Tags (Modal Popup)
  }

  .create-project select[name="create-project[categoryArray][]"] {
    height: 140px;
  }

  .create-project select[name="create-project[categoryArray][]"] option,
  .create-project select[name="create-project[financingArray][]"] option {
    white-space: normal;
  }

  .create-project label[for="shortName"],
  .create-project label[for="bearerInstId"] {
    margin-left: 15px;
    width: 166px;
  }
  .create-project input[name="create-project[shortName]"],
  .create-project select[name="create-project[bearerInstId]"] {
    width: 160px;
  }

  .create-project label[for="plz"],
  .create-project label[for="projPlz"] {
    margin-left: 15px;
    width: 80px;
  }
  .create-project input[name="create-project[plz]"],
  .create-project input[name="create-project[projPlz]"] {
    width: 65px;
  }

  .create-project label[for="city"],
  .create-project label[for="projCity"] {
    margin-left: 15px;
    width: 36px;
  }
  .create-project select[name="create-project[city]"],
  .create-project select[name="create-project[projCity]"] {
    width: 130px;
  }

  .create-project select[name="create-project[equalAddress]"] {
    width: 80px;
  }

  .create-project input[name="create-project[email]"] {
    width: 160px;
  }

  .create-project label[for="phone"] {
    margin-left: 25px;
    width: 55px;
  }
  .create-project input[name="create-project[phone]"] {
    width: 150px !important;
  }
  .create-project input[name="create-project[contactPerson]"] {
    width: 350px !important;
  }

  .create-project label[for="web"] {
    margin-left: 15px;
    width: 40px;
  }
  .create-project input[name="create-project[web]"] {
    width: 125px;
  }

  .create-project label[for="startSituation"],
  .create-project label[for="target"],
  .create-project label[for="action"],
  .create-project label[for="partner"],
  .create-project label[for="captcha_value"] {
    vertical-align: top;
  }

  .create-project textarea[name="create-project[startSituation]"],
  .create-project textarea[name="create-project[target]"],
  .create-project textarea[name="create-project[action]"],
  .create-project textarea[name="create-project[partner]"] {
    width: 541px;
  }

  .create-project label[for="financingArray"] {
    margin-left: 15px;
    width: 106px;
  }
  .create-project select[name="create-project[financingArray][]"] {
    width: 220px;
    height: 92px;
  }
  .create-project a.financingPopupLink {
    width: 220px;
    display: block;
  }

  .create-project label[for="effect"] {
    margin-left: 105px;
    width: 115px;
  }
  .create-project select[name="create-project[effect]"] {
    width: 170px;
  }

  .create-project input[name="create-project[beginning]"],
  .create-project input[name="create-project[ending]"] {
  //.create-project input[name="create-project[partner]"] {
    width: 115px;
  }

  .create-project label[for="ending"] {
    width: 90px;
    margin-left: 35px;
  }

  .create-project label[for="statusId"] {
    width: 195px !important;
  }
  .create-project select[name="create-project[statusId]"] {
    width: 120px;
  }

  .create-project input[type="number"]::-webkit-inner-spin-button {
    display: none;
  }

  .create-project label[for="beginning"] {
    width: 112px;
    margin-left: 32px;
  }


  .create-project input[type="date"] {
    position: relative;
  }
  .create-project input[type="date"]::-webkit-inner-spin-button {
    display: none;
  }
  .create-project input[type="date"]::-webkit-calendar-picker-indicator {
    position: relative;
    color: rgba(0,0,0,0);
    opacity: 1;
    z-index: 10;
    cursor: pointer;
  }
  .create-project input[type="date"]::-webkit-calendar-picker-indicator:hover {
    background-color: rgba(0,0,0,0);
  }
  .create-project input[type="date"]:after {
    content: '\e908';
    font-family: 'icomoon';
    position: absolute;
    right: 10px;
    display: block;

  }
  .create-project input[type="date"]::-webkit-calendar-picker-indicator:after {
    content: '';
    background: $red;
    height: 26px;
    width: 26px;
    display: block;
    position: absolute;
    top: 0;
    z-index: 100;
  }
  //.create-project input[type="date"]::-webkit-clear-button {
  //  content: '';
  //}

  .input-file-field {
    width: 200px;
  }

  .create-project .copyrights {
    margin-left: 25px;
    width: 280px;
  }

  .checkCopyright {
    margin-left: 15px;
  }

  .proj-item__bearer {
    position: inherit;
    display: block;
    clear: both;
    margin-top: 20px;
  }

  html.zoom .create-project {

    .form-group {
      width: 100%;
    }

    .form-group-inline-md {
      width: 100%;
      float: none;
    }

    .form-group-inline-md > div {
      padding-bottom: 10px;
    }

    label {
      margin-left: 0!important;
      width: 100%;
    }

    .cell-label {
      width: 40%;
    }

    .form-control {
      width: 100%;
      height: 2rem;
      font-size: 1rem;
    }

    textarea.form-control {
      width: 100%;
      height: auto;
      font-size: 1rem;
    }

    .generate {
      display: table-row;
      width: 100%;
      text-align: right;
    }

    .generate .generate_part:last-of-type {
      margin-right: 0;
    }

  }
*/

}

@media (min-width: $screen-tablet) and (max-width: 991px) {

  body {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }

}

/* Mittlere Geräte (Desktop-PCs, 992px und breiter) */
@media (min-width: $screen-desktop) {

  .popover.left {
    right: 15px;
  }

  a[data-toggle="popover"] {
    top: 1px;
    right: -22px;
  }

  /***** nav *****/

  nav.navbar {
    border-bottom: 0;
    margin-bottom: 20px;
    box-shadow: none;
  }

  .navbar-header {
    position: relative;
    z-index: 1;
  }

  .navbar-header .navbar-home {
    display: block;
    padding: 10px 10px 10px 0;
    margin-right: 10px;
    font-size: 29px;
    margin-left: 0;
    float: none;
    height: 55px;
  }

  .nav > li > a:hover,
  .nav > li > a:focus,
  #navbar .rememberlist:hover {
    padding-top: 13px;
    margin-top: -3px;
    box-shadow: 2px 2px 6px #cccccc;
  }

  .navbar__searchbody {
    display: inline-block;
    background: $first-grey;
    box-shadow: 1px 1px 0 $second-grey;
    width: 200px;
    //height: 20px;
    padding: 1px;
    float: left;
    margin-top: 10px;
    margin-right: 5px;
  }

  .navbar__searchbody .btn {
    background: #fff;
    color: $red;
    border: 0;
    border-radius: 0;
    //height: 18px;
    width: 18px;
    font-size: 1.3rem;
    padding: 0;
    float: left;
  }

  .navbar__searchbody .btn span {
    display: inherit;
    font-weight: 700;
  }

  .navbar__searchbody input {
    background: $first-grey;
    border: 0;
    padding: 0px 5px;
    width: 180px;
    float: left;
    font-size: 0.9rem;
  }


  /***** Nav fixed *****/
/*
  nav.fixed-desktop {
    top: 25px;
  }

  nav.fixed-desktop #navbar {
    height: $navbar-height;
  }

  .nav > li > a:hover:before,
  .nav > li > a:focus:before,
  #navbar .rememberlist:hover:before {
    content: '';
    position: absolute;
    top: 0px;
    right: -4px;
    border-color: transparent #b11233;
    border-style: solid;
    border-width: 3px 0 0 4px;
  }


  nav.fixed-desktop + div {
    margin-top: 75px;
  }

  .fixed-desktop .navbar__outer {
    position: relative;
  }

  .fixed-desktop .navbar__outer:before,
  .fixed-desktop .navbar__outer:after {
    content: '';
    bottom: 0;
    border-color: #fff;
    border-style: solid;
    border-width: 3px;
    position: absolute;
    height: 200px;
    background-color: #fff;
    z-index: 1001;
  }

  .fixed-desktop .navbar__outer:before {
    left: -6px;
  }

  .fixed-desktop .navbar__outer:after {
    right: -6px;
  }

  .fixed-desktop .navbar__inner-outer {
    position: relative;
  }

  .fixed-desktop .navbar__inner-outer:before,
  .fixed-desktop .navbar__inner-outer:after {
    content: '';
    bottom: -3px;
    border-color: transparent #90908f;
    border-style: solid;
    position: absolute;
    z-index: 999;
  }

  .fixed-desktop .navbar__inner-outer:before {
    border-width: 0 4px 3px 0;
    left: -4px;
  }

  .fixed-desktop .navbar__inner-outer:after {
    border-width: 0 0 3px 4px;
    right: -4px;
  }*/

  .main-slider__item {
    height: 510px;
  }

  .topics__item {
    height: 232px;

    img {
      height: 232px;
      width: auto;
      max-width: inherit;
    }
  }

  /******* create-project *******/

  .char_num {
    display: table-cell;
    vertical-align: bottom;
    padding-left: 8px;
    text-align: right;
    //float: none;
  }

  .create-project .form-group {
    display: table;
  }

  .create-project .form-group:before,
  .create-project .form-group:after {
    //content: '';
    display: table;
    clear: both;
  }

  .form-group-inline-md {
    float: left;
    margin-bottom: 0;
    display: table-row;
  }

  .create-project .cell {
    display: table-cell;
  }

  .create-project .cell-label {
    vertical-align: top;
    padding-top: 4px;
  }

  .create-project .cell-label--middle {
    vertical-align: middle;
    padding-top: 0;
  }

  .create-project .cell--top {
    vertical-align: top;
  }

  .create-project label {
    margin-bottom: 0;
  }

  .create-project .left-label {
    width: 195px;
  }

  .generate-outer {
    margin-top: -10px;
  }

  .generate {
    margin-left: 195px;
    margin-top: 10px;
  }

  .create-project input[name="create-project[name]"],
  .create-project input[name="create-project[bearerName]"],
  .create-project input[name="create-project[street]"],
  .create-project input[name="create-project[bearerStreet]"],
  .create-project input[name="create-project[contactPerson]"],
  .create-project select[name="create-project[targetGroupId]"],
  .create-project select[name="create-project[categoryArray][]"],
  .create-project button.categoryPopupLink {
    width: 220px;
    display: block; //Für die a-Tags (Modal Popup)
  }

  .create-project select[name="create-project[categoryArray][]"] {
    height: 140px;
  }

  .create-project select[name="create-project[categoryArray][]"] option,
  .create-project select[name="create-project[financingArray][]"] option {
    white-space: normal;
  }

  .create-project label[for="shortName"],
  .create-project label[for="bearerInstId"] {
    margin-left: 30px;
    width: 185px;
  }
  .create-project input[name="create-project[shortName]"],
  .create-project select[name="create-project[bearerInstId]"] {
    width: 175px;
  }

  .create-project label[for="plz"],
  .create-project label[for="bearerPlz"] {
    margin-left: 30px;
    width: 90px;
  }
  .create-project input[name="create-project[plz]"],
  .create-project input[name="create-project[bearerPlz]"] {
    width: 65px;
  }

  .create-project label[for="city"],
  .create-project label[for="bearerCity"] {
    margin-left: 25px;
    width: 35px;
  }
  .create-project select[name="create-project[city]"],
  .create-project input[name="create-project[bearerCity]"] {
    width: 145px;
  }

  .create-project select[name="create-project[equalAddress]"] {
    width: 80px;
  }

  .create-project input[name="create-project[email]"] {
    width: 272px;
  }

  .create-project label[for="phone"] {
    margin-left: 55px;
    width: 55px;
  }
  .create-project input[name="create-project[phone]"] {
    width: 150px;
  }

  .create-project input[name="create-project[contactPerson]"] {
    width: 350px !important;
  }

  .create-project label[for="web"] {
    margin-left: 25px;
    width: 40px;
  }
  .create-project input[name="create-project[web]"] {
    width: 272px;
  }

  .create-project label[for="startSituation"],
  .create-project label[for="target"],
  .create-project label[for="action"],
  .create-project label[for="partner"],
  .create-project label[for="captcha_value"] {
    vertical-align: top;
  }

  .create-project textarea[name="create-project[startSituation]"],
  .create-project textarea[name="create-project[target]"],
  .create-project textarea[name="create-project[action]"],
  .create-project textarea[name="create-project[partner]"] {
    width: 610px;
  }

  .create-project label[for="financingArray"] {
    margin-left: 35px;
    width: 185px;
  }
  .create-project select[name="create-project[financingArray][]"] {
    width: 225px;
    height: 92px;
  }
  .create-project button.financingPopupLink {
    width: 170px;
    display: block;
  }

  .create-project label[for="effect"] {
    margin-left: 105px;
    width: 115px;
  }
  .create-project select[name="create-project[effect]"] {
    width: 170px;
  }

  .create-project label[for="ending"] {
    width: 90px;
    margin-left: 35px;
  }

  .create-project label[for="beginning"] {
    width: 112px;
    margin-left: 32px;
  }

  .create-project input[name="create-project[beginning]"],
  .create-project input[name="create-project[ending]"] {
  //.create-project input[name="create-project[partner]"] {
    width: 110px;
  }

  .create-project label[for="statusId"] {
      width: 195px !important;
    //margin-left: 10px;
    //width: 90px;
  }
  .create-project select[name="create-project[statusId]"] {
    width: 120px;
  }

  .create-project input[type="number"]::-webkit-inner-spin-button {
    display: none;
  }

  .input-file-field {
    width: 220px;
  }

  .create-project .copyrights {
    margin-left: 25px;
    width: 280px;
  }

  .checkCopyright {
    width: 528px;
    margin-left: 15px;
  }

  .proj-item__bearer {
    position: absolute;
    bottom: 0;
    display: inline;
    clear: inherit;
    margin-top: 0;
  }

  html.zoom .proj-item__bearer  {
    position: relative;
  }

  html.zoom .create-project {

    .form-group {
      width: 100%;
    }

    .form-group-inline-md {
      width: 100%;
      float: none;
    }

    .form-group-inline-md > div {
      padding-bottom: 10px;
    }

    label {
      margin-left: 0!important;
      width: 100%;
    }

    .cell-label {
      width: 40%;
    }

    .form-control {
      width: 100%;
      height: 2rem;
      font-size: 1rem;
    }

    textarea.form-control {
      width: 100%;
      height: auto;
      font-size: 1rem;
    }

    .generate {
      display: table-row;
      width: 100%;
      text-align: right;
    }

    .generate .generate_part:last-of-type {
      margin-right: 0;
    }

  }

}
@media screen and (max-width: 1023px) {
  .startseite__img-box {
    display: none;
  }
  .landingpage-project-img {
    display: none;
  }
}

@media screen and (max-width: 769px) {
  .path-clip {
    clip-path: polygon(50% 31%, 0 0, 100% 0);
    align-items: flex-start;
    height: 265px;
    justify-content: center;
    margin-top: 30px;
    padding: 30px 0;
  }
  .landingpage-create-project__los-geht {
    padding: 0 ;
    margin-top: -160px;
  }
  .landingpage-insert-box {
    margin-bottom: 50px;
  }
  .landingpage-insert-items {
    text-align: center;
  }
  .future-project-box__img {
      width: 100%;
  }
}



@media screen and (max-width: 526px) {
  .start-seite-slider {
    padding: 15px 20px;
  }
  .startseite-slider__head {
    padding: 2px;
  }
  .future-project-box__img {
    height: auto;
  }

  .startseite-slider__overlay {
    padding: 2px;
  }

  .startseite-project {
    padding: 10px;
  }
  .startseite-arrows {
    display: none !important;
  }

  .landingpage-insert-box {
    padding: 15px;
  }

  .landingpage-create-project__los-geht {
    padding: 18px 0;
    margin-top: -180px;
  }
  .landingpage-project-img {
    display: none;
  }
  .landingpage-project-box {
    padding: 12px 7px;
    margin-top: 0;
  }
  .main-slider__title {
    font-size: 1.26rem!important;
  }
  .startseite-slider__sbutitle {
     font-size: 15px;
     padding-bottom: 10px;
  }
  .startseite-slider-btn {
    padding: 5px 28px;
    margin-top: 10px;
  }
  .landingpage-create-project__title {
    font-size: 1.35rem;
  }
  .landingpage-create-project__sbutitle {
    font-size: 15px;
  }
  .landingpage-project {
    padding: 25px 5px;
  }
  .path-clip {
    clip-path: polygon(50% 25%, 0 0, 100% 0);
    padding: 15px 0;
  }
}